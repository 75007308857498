export const environment = {
  sURL: 'https://dev-api.bookiekit.net/v1/api/',
  bURL: 'https://dev-api.bookiekit.net',
  mURL: 'https://dev-market.bookiekit.net',
  enableSetRCsubaccount: true,
  enableDashboard: true,
  firstIndexPnl: false,
  riskCalculatorURL: 'https://dev-feed-rust.bookiekit.net/risk_calculator',
  sessionTimeout: 3600000
};
