import { environment } from '../../../../../../../shared/src/lib/environments/environment';
import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  ...(environment.enableDashboard ? [
    {
      displayName: 'Dashboard',
      iconName: '',
      route: '/starter',
    }
  ] : []),

  {
    displayName: 'Users',
    iconName: '',
    route: 'home/users',
  },
  // {
  //   displayName: 'Reports',
  //   iconName: '',
  //   route: 'home/report',
  // },
  {
    displayName: 'Account Statement',
    iconName: '',
    route: 'home/account-statement',
  },
  {
    displayName: 'Betting P/L',
    iconName: '',
    route: 'home/settled-markets',
  },
  {
    displayName: 'Live PnL',
    iconName: '',
    route: 'home/live-markets',
  },
  {
    displayName: 'Bet Info',
    iconName: '',
    route: 'home/bet-info',
  },
  {
    displayName: 'White Labels',
    iconName: '',
    route: 'home/white-label',
  },

  {
    displayName: 'Betfair Accounts',
    iconName: '',
    route: 'configuration/betfair-accounts',
  },

  {
    displayName: 'Commission',
    iconName: '',
    route: 'home/commission',
  },
  {
    displayName: 'Risk Taking',
    iconName: '',
    route: 'home/risk',
  },
  {
    displayName: 'Exchange Rate',
    iconName: '',
    route: 'configuration/exchange-rate',
  },

  {
    displayName: 'Supported Countries',
    iconName: '',
    route: 'settings/supported-countries',
  },
  {
    displayName: 'Supported Sports',
    iconName: '',
    route: 'settings/supported-sports',
  },
  {
    displayName: 'Resettlement',
    iconName: '',
    route: 'home/resettlement',
  },
  {
    displayName: 'Roles',
    iconName: '',
    route: 'settings/roles',
  },
  {
    displayName: 'Change Password',
    iconName: '',
    route: 'home/reset-password',
  },
  {
    displayName: 'Feature Flags',
    iconName: '',
    route: 'configuration/feature-flags',
  },
  {
    displayName: 'Dynamic Data',
    iconName: '',
    route: 'home/user-hierarchy',
  },
  {
    displayName: 'Audit Log',
    iconName: '',
    route: 'home/audit-log',
  },
  {
    displayName: 'Services Config',
    iconName: '',
    route: 'home/services-config',
  }
];


